import React from "react";
import { ScrollView } from "react-native-gesture-handler";
import { appConfig } from "../../../../config/config";

import { ScreenContainer } from "../../../layout/ScreenContainer";
import { MonthlyPayments } from "./MonthlyPayments";
import { RoundupAmounts } from "./RoundupAmounts";
import { TotalAmounts } from "./TotalAmounts";

export const DashboardScreen = (): React.ReactElement => {
  return (
    <ScrollView>
      <ScreenContainer backgroundColor={appConfig.dashboardBackground}>
        <TotalAmounts />
        <RoundupAmounts />
        {/* <DirectDebitAmounts /> */}
        <MonthlyPayments />
      </ScreenContainer>
    </ScrollView>
  );
};
