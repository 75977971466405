import React from 'react';
import { t } from 'react-native-tailwindcss';
import { Text } from 'react-native';
import { NavigationProp, useNavigation } from '@react-navigation/native';
import { getAuth, signOut } from 'firebase/auth';
import { useQueryClient } from 'react-query';
import { useAuth } from '../../context/authContext';
import { firebaseApp } from '../../config/firebase';
import { QueryKey } from '../../services/api/query';
import { useError } from '../../context/errorContext';

export const Footer = (): React.ReactElement => {
  const { setCriticalError } = useError();
  const navigation = useNavigation<NavigationProp<any>>();
  const queryClient = useQueryClient();
  const { authenticated } = useAuth();

  return (
    <Text style={[styles.footerText]}>
      <Text
        style={styles.coloredLink}
        onPress={() => navigation.navigate('Help')}
      >
        Help{' '}
      </Text>
      <Text
        style={styles.coloredLink}
        onPress={() => navigation.navigate('Terms')}
      >
        Terms{' '}
      </Text>
      <Text
        style={styles.coloredLink}
        onPress={() => navigation.navigate('PrivacyPolicy')}
      >
        Privacy{' '}
      </Text>
      {authenticated && (
        <>
          <Text
            style={styles.coloredLink}
            onPress={() => {
              setCriticalError(null);
              const auth = getAuth(firebaseApp);
              signOut(auth).then(() => {
                queryClient.setQueryData(QueryKey.User, () => null);
                queryClient.setQueryData(QueryKey.Accounts, () => null);
              });
            }}
          >
            Logout
          </Text>
        </>
      )}
    </Text>
  );
};

const styles = {
  footerText: [t.textCenter, t.mT2],
  coloredLink: [t.textBlue400, t.mR4],
};
