import { NavigationProp, useNavigation } from "@react-navigation/native";
import React, { useEffect, useState } from "react";
import { View } from "react-native";
import { t } from "react-native-tailwindcss";
import Button from "../../../atoms/Button";
import Card from "../../../atoms/Card";
import Currency from "../../../atoms/Currency";
import ClientLogo from "../../../atoms/ClientLogo";
import usePayments from "../../../../hooks/usePayments";
import { AppScreensParamList } from "../../../../navigation/app-screens";
import { HCenterStack } from "../../../layout/HStack";
import { MediumText } from "../../../atoms/MediumText";
import { LineTitle } from "../../../molecules/LineTitle";
import { useUser } from "../../../../context/userContext";

export const TotalAmounts = (): React.ReactElement => {
  const navigation = useNavigation<NavigationProp<AppScreensParamList>>();
  const { totalCleared } = usePayments();
  const { user } = useUser();
  const [remainingAmount, setRemainingAmount] = useState(0);

  useEffect(() => {
    setRemainingAmount(
      Math.max(user?.contributionLimit?.limit ?? 0 - totalCleared, 0),
    );
  }, [totalCleared, user]);

  return (
    <Card style={styles.card}>
      <HCenterStack>
        <View style={{ height: 80, width: "100%" }}>
          <ClientLogo />
        </View>
      </HCenterStack>
      <LineTitle title="Totals" />
      <HCenterStack>
        {user?.contributionLimit && (
          <MediumText style={{ marginHorizontal: 10 }}>
            Debt: <Currency cents={user.contributionLimit.limit} />
          </MediumText>
        )}
        <MediumText style={{ marginHorizontal: 10 }}>
          Donated: <Currency cents={totalCleared} />
        </MediumText>
        {user?.contributionLimit && (
          <MediumText style={{ marginHorizontal: 10 }}>
            Remaining: <Currency cents={remainingAmount} />
          </MediumText>
        )}
      </HCenterStack>
      <HCenterStack style={[t.pT4]}>
        <View style={t.wFull}>
          <Button
            label="Donate Now"
            onPress={() => navigation.navigate("PayNow")}
          />
        </View>
      </HCenterStack>
    </Card>
  );
};
const styles = {
  card: [t.flex, t.flexCol, t.p5, t.mB6, t.bgWhite],
};

export default TotalAmounts;
